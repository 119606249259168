import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import ParcelDetail, { EImgType } from '../../components/ParcelDetail';

import ParcelCard from '../../components/ParcelCard';
import AdditionalInfoBlock from '../../components/AdditionalInfoBlock';
import PhotoDisplayList from '../../components/PhotoDisplayList';

import { useQuery } from '../../utils/useQuery';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { useModal } from '../../context/ModalContext';

import { useSendParcel } from '../../context/SendParcelContext';
import { getUserInfo } from '../../service/user/parcel';
import { createNewRequest } from '../../service/parcel/parcel';
import DeleteRequestModal from '../../components/Modals/DeleteRequest';
import { EToast, useToast } from '../../context/ToastContext';
import { usePhotos } from '../../context/PhotosContext';
import DoneIcon from '../../assets/icons/done.svg';
import { useTranslation } from 'react-i18next';
import { ROUTE_PATHS } from '../../const/routes';
import globalRequestStore from '../../store';


const AdditionalInfoDetailPage = () => {
  const location = useLocation()
  const state = location?.state
  const query = useQuery();
  const id = query.get('id');
  const tripId = query.get('tripId');

  const dates = query.get('dates');
  const price = query.get('price');
  const {t} = useTranslation();
  const [userInfo, setUserInfo] = useState<any>()
  const {showToast} = useToast()
  const {clearPhotos} = usePhotos()
  const [searchParams] = useSearchParams();
  const destination = searchParams.get('destination');
  const {
    from, to, parcelSize, dateRange,
    handleReset,
    parcelPrices
  } = useSendParcel();
  const {info, handleUpdatePhoto, handleInfoChange, photos} = usePhotos()


  useEffect(() => {
    !!id && getUserInfo(+id).then(data => setUserInfo(data))

  }, []);


  const navigate = useNavigate()

  const {openModal} = useModal();

  const handleCreateNewRequest = () => {
    const photosData = photos
      .filter(photoData => photoData.photo)
      .map(photoData => photoData.photo);
    createNewRequest({
      size: parcelSize,
      additional_info: info,
      trip: String(tripId),
      photos: photosData

    }).then((response) => {
      handleReset()
      clearPhotos()
      navigate(ROUTE_PATHS.TRACKING)
      showToast(t('waiting_for_approval'), EToast.ACCEPT)
    }).catch((e) => {
      globalRequestStore.setError(JSON.stringify(e))
    })
  }

  const handleDeleteRequest = () => {
    handleReset()
    clearPhotos()
    navigate(ROUTE_PATHS.PARCEL_SEND)
  }

  const handleNavigateToUserInfo = () => navigate(`${ROUTE_PATHS.USER_INFO}?id=${id}`);
  const handleOpenRejectRequestModal = () => {
    openModal(DeleteRequestModal,
      {onDeleteRequest: handleDeleteRequest});
  };

  const ButtonsArray = [
    {
      info: t('additional_info_detail_page.btn_text'),
      condition: true,
      buttons: [
        {
          color: 'fill',
          text: t('submit_button'),
          action: handleCreateNewRequest
        },
        {
          color: 'bezeled',
          text: t('additional_info_detail_page.delete'),
          action: handleOpenRejectRequestModal
        }
      ]
    },
  ]
  const getButtonsByStatus = () => {
    const currentButton = ButtonsArray.find(item => item.condition)
    return (
      <footer className={styles.footer}>
        {currentButton?.info && <p style={{marginBottom: 8}}>{currentButton?.info}</p>}
        {currentButton?.buttons.map(btn => (
          <div className={styles.button}>
            <Button
              text={btn.text}
              color={btn.color}
              size="large"
              onClick={btn.action}
            />
          </div>
        ))}
      </footer>
    )
  }

  const fromTo = destination?.split(' - ')
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <img src={DoneIcon} alt="DoneIcon"/>
          <h2>{t('additional_info_detail_page.title')}</h2>
          <p>
            {t('additional_info_detail_page.description')}
          </p>
        </div>
        <ParcelDetail
          imgType={EImgType.BOX}
          dateRange={dates || {from: dateRange[0].startDate, to: dateRange[0].endDate}}
          size={parcelSize}
          price_detail={{
            key: ['M', 'L'].includes(parcelSize) ? t('price.price_per_item') : t('price.price_per_kg'),
            value: price ? +price : 0
          }}
          place={{from: fromTo?.[0] || from?.title, to: fromTo?.[1] || to?.title}}
          // status={currentStatus}
        />
        <ParcelCard
          onClick={handleNavigateToUserInfo}
          avatarImg={userInfo?.photo_link}
          name={userInfo?.name || ''}
          rating={userInfo?.rating}
        />

        <AdditionalInfoBlock
          text={
            info
          }
        />

        <PhotoDisplayList data={photos.filter(item => item.photo)}/>


      </div>
      {getButtonsByStatus()}
    </>
  );
};
export default AdditionalInfoDetailPage;
