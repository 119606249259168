import React from "react";
import styles from "./styles.module.css";
import DeliveryTripStatus, { EDeliveryTripStatus } from "./DeliveryTripStatus";
import { ReactComponent as RightArrow } from "../../assets/icons/arrowRight.svg";
import { getCardFormat } from "../../utils/dateUtil";

interface IProps {
  dateRange: { from: string; to: string };
  place: { from: string; to: string };
  status?: EDeliveryTripStatus;
  requests?: {
    current: number;
    max: number;
  };
  onClick?: ()=>void;
}
const DeliveryTripCard: React.FC<IProps> = (props) => {
  return (
    <div className={styles.wrapper} onClick={props.onClick}>
      <div className={styles.content} >
        <div className={styles.about}>
          <div className={styles.info}>
            <p className={styles.place}>
              {props.place.from} - {props.place.to}
            </p>
            <p className={styles.date}>
              {getCardFormat(props.dateRange.from)} -{" "}
              {getCardFormat(props.dateRange.to)}
            </p>
          </div>
        </div>
        <div className={styles.action}>
          <RightArrow />
        </div>
      </div>
      {props.status && (
        <DeliveryTripStatus status={props.status} requests={props.requests} />
      )}
    </div>
  );
};
export default DeliveryTripCard;
