import moment from "moment";
import { addYears, isBefore, isAfter, subDays, addWeeks } from 'date-fns';

export const formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${day}.${month}`;
};

export const formatFullDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear())
    return `${day}.${month}.${year}`;
};

export const transformDate = (date: string): string => {
    const arr = date.split("-");

    return `${arr[2]}.${arr[1]}`
}

export const getCardFormat = (date: string) => moment(date).format("DD.MM");

export const getNearestDates = (results: any[]) => {
    if (!!results.length) {
        const today = new Date();

        return results.filter(result => {
            const startDate = new Date(result.dispatch_period_start);
            const endDate = new Date(result.dispatch_period_end);

            const weekBefore = subDays(today, 7);
            const twoWeeksAfter = addWeeks(today, 2);

            const isInRange =
                (isAfter(startDate, weekBefore) && isBefore(startDate, twoWeeksAfter)) ||
                (isAfter(endDate, weekBefore) && isBefore(endDate, twoWeeksAfter)) ||
                (isBefore(startDate, weekBefore) && isAfter(endDate, twoWeeksAfter));

            return isInRange;
        }).sort((a, b) => new Date(a.dispatch_period_start).getTime() - new Date(b.dispatch_period_start).getTime());
    } else {
        return []
    }

};


