import styles from "./styles.module.css";
import { EParcelStatus } from '../../ParcelCard/ParcelStatus';
import {useTranslation} from "react-i18next";
export enum EDeliveryTripStatus {
  REQUESTS = 1,
  DELIVERED_GIVE_FEEDBACK = 2,
  DELIVERED = 3,
}
interface IProps {
  status: EDeliveryTripStatus;
  requests?: {
    current: number;
    max: number;
  };
}
export function mapTripStatus(status: string): EDeliveryTripStatus | undefined {
  switch (status) {
    case "Active":
      return EDeliveryTripStatus.REQUESTS;
    case "Delivered":
      return EDeliveryTripStatus.DELIVERED;
    case "Delivered - Give feedback":
      return EDeliveryTripStatus.DELIVERED_GIVE_FEEDBACK;
    default:
      return undefined;
  }
}
const DeliveryTripStatus: React.FC<IProps> = ({ status, requests }) => {
  const { t } = useTranslation();


  const getParamas = () => {
    switch (status) {
      case EDeliveryTripStatus.REQUESTS:
        return {
          bgColor: "#E9E9EA",
          color: "#000000",
          text: `${t('delivery_trip_status.requests')} (${requests?.current}/${requests?.max})`,
        };
      case EDeliveryTripStatus.DELIVERED_GIVE_FEEDBACK:
        return {
          bgColor: "#DDE8FF",
          color: "#007AFF",
          text: t('delivery_trip_status.give_feedback'),
        };
      case EDeliveryTripStatus.DELIVERED:
        return { bgColor: "#DDE8FF", color: "#007AFF", text: t('delivery_trip_status.delivered') };
    }
  };
  const current = getParamas();
  return (
    <p
      className={styles.status}
      style={{
        backgroundColor: current.bgColor,
        color: current.color,
      }}
    >
      {current.text}
    </p>
  );
};
export default DeliveryTripStatus;
