import loader from '../../assets/images/loader.png'
import styles from './styles.module.css'

const Loader = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.loader}>
        <img src={loader} alt="loader" height={40} width={40}/>
      </div>
    </div>
  )
}
export default Loader
