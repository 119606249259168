import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../components/Button/Button';
import MainImg from './../../assets/icons/shippingbox.svg';
import Arrows from './../../assets/icons/arrows.svg';

import style from './PageAboutYou.module.css';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CustomDatePicker from '../../components/CustomDatePicker';
import { addProtocol } from '../../utils/addProtocol';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import globalRequestStore from '../../store';
import { validateName } from '../../utils/validation';
import { ROUTE_PATHS } from '../../const/routes';
import styles from '../AdditionalInfoPage/styles.module.css';
import TextArea from '../../components/TextArea';
import { conditionalClassNames } from '../../utils/conditionalClassNames';

export const PageAboutYou = () => {
  const {t} = useTranslation();

  const option: { title: string; value: string }[] = [
    {title: t('edit_profile_page.gender_male'), value: 'male'},
    {title: t('edit_profile_page.gender_female'), value: 'female'},
    {title: t('edit_profile_page.gender_not_specified'), value: 'not_specified'},
  ];

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();


  const tgId = searchParams.get('tg-id');
  const hashedTgId = searchParams.get('hashed-tg-id');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Gender');
  const [inputValue, setInputValue] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [bio, setBio] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<string | null>(null);
  const bioRef = useRef<HTMLTextAreaElement>(null);
  const instagramRef = useRef<HTMLInputElement>(null);
  const [keyboardShow, setKeyboardShow] = useState(false)
  const [spaceShow, setSpaceShow] = useState(false)

  useEffect(() => {
    const checkUserExists = async () => {
      try {
        globalRequestStore.startLoading();
        const response = await fetch(`https://admin.delivermypack.online/api/users/`, {
          headers: {
            'Content-Type': 'application/json',
            'tg-id': hashedTgId || '',
          },
        })

        if (response.ok) {
          const data = await response.json();
          navigate(`/parcel-send?hashed-tg-id=${hashedTgId}`);
        } else {
          navigate(`/?tg-id=${tgId}&hashed-tg-id=${hashedTgId}`);
        }
        globalRequestStore.finishLoading();
      } catch (error: any) {
        globalRequestStore.setError(error.message);
      }
    };
    checkUserExists();
  }, [hashedTgId, navigate]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleBioChange = (event) => {
    setBio(event.substring(0, 500));
  };
  const handleFocus = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      setTimeout(() => {
        if (ref.current) {
          window.scrollBy({
            top: window.innerHeight,
            behavior: 'smooth',
          });
        }
      }, 1000);
    }
  };
  const handleDateChange = (date: string) => {
    setDateOfBirth(date);
  };

  const handleSubmit = async () => {
    if (!validateName(name)) {
      globalRequestStore.setError(t('error_label.name') + name);
      return
    }
    const formData = {
      name,
      sex: selectedOption,
      bio,
      social_link: addProtocol(inputValue),
      birthdate: dateOfBirth ? moment(dateOfBirth).format('YYYY-MM-DD') : null,
      tg_id: tgId,
      hashed_tg_id: hashedTgId,
    };

    try {
      const response = await fetch(
        'https://admin.delivermypack.online/api/users/register/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error(t('error_label.link'));
      }
      navigate(`${ROUTE_PATHS.PARCEL_SEND}?hashed-tg-id=${hashedTgId}`);
      console.log('Form submitted successfully!');
    } catch (error:any) {
      globalRequestStore.setError(error.message);
    }
  };


  useEffect(() => {
    document.querySelectorAll('#needScroll').forEach(el => {
      el.addEventListener('focus', () => {
        window.scrollTo(0, document.body.scrollHeight);
        setKeyboardShow(true)
      });

      el.addEventListener('blur', () => {
        setKeyboardShow(false)
      });
    });
  }, []);
  useEffect(() => {
    document.querySelectorAll('#space').forEach(el => {
      el.addEventListener('focus', () => {
        setSpaceShow(true)
      });

      el.addEventListener('blur', () => {
        setSpaceShow(false)
      });
    });
  }, []);
  return (
    <>
      <div className="container">
        <div className={conditionalClassNames(style.mainWrapper, keyboardShow && style.mainWrapperWithKeyboard, spaceShow && style.space)}>
          <div className={style.wrapper}>
            <img src={MainImg} alt="" className={style.mainImg}/>
            <h1 className={style.title}>{t('about_you_page.title')}</h1>
            <p className={style.text}>
              {t('about_you_page.subtitle')}
            </p>
          </div>

          <div className={style.wrapper_input}>
            <p className={style.label}>{t('about_you_page.about_you_label')}</p>

            <input
              id='space'
              className={style.input}
              type="text"
              value={name}
              onChange={handleNameChange}
              placeholder={t('about_you_page.name_placeholder')}
            />

            <div
              className={style.blockGender}
              onClick={() => setIsOpen(!isOpen)}
            >
              <span
                className={
                  selectedOption === 'Gender'
                    ? style.defaultGender
                    : style.selectedGender
                }
              >
                {option.find((item) => item.value === selectedOption)?.title || t('edit_profile_page.gender_placeholder')}
              </span>

              <img src={Arrows} alt=""/>

              {isOpen && (
                <div className={style.option}>
                  {option.map((item, key) => (
                    <div
                      key={key}
                      className={style.optionItem}
                      onClick={() => setSelectedOption(item.value)}
                    >
                      <span className={style.optionText}>{item.title}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <CustomDatePicker onDateChange={handleDateChange} initialDate={dateOfBirth ? new Date(dateOfBirth) : undefined}/>
            <p className={style.label}>{t('edit_profile_page.bio')}</p>
            <TextArea
              minHeight={84}
              id="needScroll"
              value={bio || ''}
              onChange={handleBioChange}
              className={styles.textArea}
              placeholder={t('edit_profile_page.bio_placeholder')}
            />
            {/*<textarea*/}
            {/*  id="needScroll"*/}
            {/*  rows={String(bio).length / 50} // Початкова кількість рядків*/}
            {/*  style={{*/}
            {/*    width: '100%',*/}
            {/*    overflow: 'hidden',*/}
            {/*    resize: 'none',*/}
            {/*  }}*/}
            {/*  onInput={(e: any) => {*/}
            {/*    e.target.style.height = 'auto';*/}
            {/*    e.target.style.height = `${e.target.scrollHeight}px`;*/}
            {/*  }}*/}
            {/*  ref={bioRef}*/}
            {/*  className={style.textArea}*/}
            {/*  placeholder={t('about_you_page.bio_placeholder')}*/}
            {/*  value={bio || ''}*/}
            {/*  onChange={handleBioChange}*/}
            {/*  onFocus={() => handleFocus(bioRef)}*/}
            {/*></textarea>*/}

            <p className={style.inputValue}>{bio.length} / 500</p>

            <input
              id="needScroll"
              ref={instagramRef}
              className={style.input}
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onFocus={() => handleFocus(instagramRef)}
              placeholder="instagram.com"
            />
          </div>
          <footer className={style.footer}>
            <div className={style.button}>
              <Button
                text={t('about_you_page.start_button')}
                color="fill"
                size="large"
                onClick={handleSubmit}
                disabled={!name.trim() || selectedOption === 'Gender'}
              />
            </div>
          </footer>
        </div>

      </div>

    </>
  );
};
