import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';

type RatingProps = {
  maxStars?: number;
  onRatingSubmit: (rating: number) => void;
};

const SetRating: React.FC<RatingProps> = ({ maxStars = 5, onRatingSubmit }) => {
  const [selectedStars, setSelectedStars] = useState(0);
  const [hoveredStars, setHoveredStars] = useState(0);

  const handleStarClick = (index: number) => {
    setSelectedStars(index);
  };

  const handleStarHover = (index: number) => {
    setHoveredStars(index);
  };

  const handleStarLeave = () => {
    setHoveredStars(0);
  };

  useEffect(() => {
    onRatingSubmit(selectedStars);
  }, [selectedStars]);

  return (
    <div className={styles.stars}>
      {Array.from({ length: maxStars }, (_, index) => (
        <Star
          key={index}
          filled={index < (hoveredStars || selectedStars)}
          onClick={() => handleStarClick(index + 1)}
          onMouseEnter={() => handleStarHover(index + 1)}
          onMouseLeave={handleStarLeave}
        />
      ))}
    </div>
  );
};

type StarProps = {
  filled: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

const Star: React.FC<StarProps> = ({ filled, onClick, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={styles.starContainer}>
      <span
        className={`${styles.star} ${filled ? styles.filled : ''}`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {filled ? '★' : '☆'}
      </span>
    </div>
  );
};

export default SetRating;
