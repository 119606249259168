import React, { useEffect, useRef, useState } from 'react';
import styles from "./styles.module.css";
import PlusIcon from "../../assets/icons/plus.svg";
import FileIcon from "../../assets/icons/file.svg";
import PhotoIcon from "../../assets/icons/photo.svg";
import PhotoLibraryIcon from "../../assets/icons/photoLibrary.svg";
import { Popover } from "../Popover";
import { useModal } from "../../context/ModalContext";
import { CameraComponent } from "../Modals/Camera";
import { PhotoDisplay } from "../PhotoDisplay";
import { conditionalClassNames } from "../../utils/conditionalClassNames";
import { generateUUID } from "../../utils/generateUUID";
import {useTranslation} from "react-i18next";
import {usePhotos} from "../../context/PhotosContext";

enum PhotoState {
  Empty,
  Loading,
  Loaded,
}

export interface PhotoItemType {
  id: string;
  photo: string | null;
}

const PhotoItem: React.FC<{
  photo: string | null;
  onRemove: () => void;
  onAddPhoto: (photo: string) => void;
}> = ({ photo, onRemove, onAddPhoto }) => {
  const { t } = useTranslation();

  const parentRef = useRef<HTMLDivElement>(null);
  const { openModal } = useModal();
  const [statePhoto, setStatePhoto] = useState<PhotoState>(PhotoState.Empty);


  const handleChooseFile = (onAddPhoto: (photo: string) => void) => {
    const fileInput = document.createElement("input");

    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.style.display = "none";
    document.body.appendChild(fileInput);

    fileInput.addEventListener('change', (event: Event) => {
      setStatePhoto(PhotoState.Loading);

      const target = event.target as HTMLInputElement;
      const file = target.files?.[0];

      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          onAddPhoto(reader.result as string);
          setStatePhoto(PhotoState.Empty);
          document.body.removeChild(fileInput);
        };

        reader.readAsDataURL(file);
      } else {
        document.body.removeChild(fileInput);
      }
    });


    fileInput.click();
  };





  const classes = conditionalClassNames(
    styles.photoItem,
    statePhoto === PhotoState.Empty && styles.empty
  );
  return (
    <>
      {photo ? (
        <PhotoDisplay photo={photo} onRemove={onRemove} />
      ) : (
        <div className={classes} ref={parentRef} onClick={()=>handleChooseFile(onAddPhoto)}>
          {statePhoto === PhotoState.Loading && (
            <div className={styles.loader} />
          )}
          {statePhoto === PhotoState.Empty && (
            <img src={PlusIcon} alt="PlusIcon" />
          )}

        </div>
      )}
    </>
  );
};
interface IProps {
  onHandleUploadPhoto: (photos: PhotoItemType[])=>void
}
export const UploadPhotoBlock: React.FC<IProps> = ({onHandleUploadPhoto}) => {
  const { photos, setPhotos } = usePhotos();


  const addPhoto = (id: string, photo: string) => {
    setPhotos((prevState) => {
      const updatedPhotos = prevState.map((item) =>
        item.id === id ? { ...item, photo } : item
      );
      if (!updatedPhotos[0].photo && updatedPhotos[1].photo) {
        [updatedPhotos[0], updatedPhotos[1]] = [updatedPhotos[1], updatedPhotos[0]];
      }
      if (updatedPhotos.every((item) => item.photo)) {
        updatedPhotos.push({ id: generateUUID(), photo: null });
      }
      return updatedPhotos;
    });
  };

  const removePhoto = (id: string) => {
    setPhotos((prevState) => {
      const updatedPhotos = prevState.filter((photo) => photo.id !== id);
      if (updatedPhotos.length < 2) {
        updatedPhotos.push({ id: generateUUID(), photo: null });
      }
      return updatedPhotos;
    });
  };
  useEffect(() => {
    onHandleUploadPhoto(photos)
  }, [JSON.stringify(photos)]);
  return (
    <div className={styles.wrapper}>
      {photos.map((item) => (
        <PhotoItem
          key={item.id}
          photo={item.photo}
          onAddPhoto={(photo) => addPhoto(item.id, photo)}
          onRemove={() => removePhoto(item.id)}
        />
      ))}
    </div>
  );
};
