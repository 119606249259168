import React, { FC, useEffect, useState } from 'react';

import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import Avatar from '../../components/Avatar/Avatar';

import styles from './styles.module.css';
import Reviews from '../../components/Reviews/Reviews';
import { getUserInfo, UserProfile } from '../../service/user/parcel';
import { useQuery } from '../../utils/useQuery';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { conditionalClassNames } from '../../utils/conditionalClassNames';


const UserInfo: FC = () => {
  const navigate = useNavigate()
  const query = useQuery();
  const id = query.get('id');

  const { t } = useTranslation();

  const [profileData, setProfileData] = useState<UserProfile>()

  useEffect(() => {
    id && getUserInfo(+id).then(data => {
      setProfileData(data)
    })
  }, []);


  if (!profileData) return null
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.content}>
        <div className={styles.profileAvatarWrapper}>
          <Avatar profileName={profileData ? profileData.name : 'P'} avatarUrl={profileData.photo_link}/>
          <div className={styles.topInfoWrapper}>
            <p className={styles.profileName}>
              {profileData ? profileData.name : '-'}
            </p>
            {profileData?.rating && (
              <p className={styles.rating}>
                <StarIcon/>
                <span>{profileData.rating}</span>
              </p>
            )}
          </div>

        </div>
        <div className={styles.bioBlock}>
          {profileData?.bio && (
            <div className={styles.bio}>
              <p>{t('user_info_page.bio')}</p>
              <div className={styles.bioDescription}>
                {profileData ? profileData.bio : '-'}
              </div>
            </div>
          )}
          {profileData?.social_link && (
            <div className={conditionalClassNames(styles.links,profileData?.bio && profileData?.social_link && styles.linksAndBio)}>
              <p>{t('user_info_page.links')}</p>
              <a
                target={'_blank'}
                href={profileData ? profileData.social_link : '#'}
              >
                {profileData ? profileData.social_link : '-'}
              </a>
            </div>
          )}
        </div>
      </div>
      <div className={styles.reviewsWrapper}>
        <Reviews isCurrentUser={false} reviews={profileData.reviews_received}/>
      </div>
    </div>
  );
};

export default UserInfo;
