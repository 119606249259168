import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getUserInfo} from "../../service/user/parcel";
import Avatar from "../../components/Avatar/Avatar";
import Reviews from "../../components/Reviews/Reviews";

import { ReactComponent as StarIcon } from "../../assets/icons/star.svg";

import styles from "./SendParcelCourierPage.module.css";
import {Button} from "../../components/Button/Button";
import {useTranslation} from "react-i18next";

import { ROUTE_PATHS } from '../../const/routes';

const SendParcelCourierPage: FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const tripId =searchParams.get('tripId')

    const { t } = useTranslation();

    const [courierData, setCourierData] = useState<any>(null);



    useEffect(() => {
        !!id && getUserInfo(+id).then(data => setCourierData(data))
    }, []);
    const onHandleNavigateToAdditionalPage = ()=>{
        navigate(`${ROUTE_PATHS.ADDITIONAL_INFO}?id=${id}&tripId=${tripId}`)
    }
    return (
        <div className={styles.pageWrapper}>
            <div className={styles.content}>
                <div className={styles.profileAvatarWrapper}>
                    <Avatar avatarUrl={courierData && courierData?.photo_link} profileName={courierData ? courierData.name : "P"} />
                    <div className={styles.topInfoWrapper}>
                        <p className={styles.profileName}>
                            {courierData ? courierData.name : "-"}
                        </p>
                        {courierData && courierData?.rating && (
                            <p className={styles.rating}>
                                <StarIcon />
                                <span>{courierData?.rating}</span>
                            </p>
                        )}
                    </div>
                    <div className={styles.tripInfoBlock}>
                        <p>{searchParams.get('destination')}</p>
                        <p className={styles.dates}>{searchParams.get('dates')}</p>
                    </div>
                </div>
                <div className={styles.bioBlock}>
                    {courierData?.bio && (
                        <div className={styles.bio}>
                            <p>{t('user_info_page.bio')}</p>
                            <div className={styles.bioDescription}>
                                {courierData ? courierData.bio : "-"}
                            </div>
                        </div>
                    )}
                    {courierData?.social_link && (
                        <div className={styles.links}>
                            <p>{t('user_info_page.links')}</p>
                            <a
                                target={"_blank"}
                                href={courierData ? courierData.social_link : "#"}
                            >
                                {courierData ? courierData.social_link : "-"}
                            </a>
                        </div>
                    )}
                </div>

            </div>
            <Reviews isCurrentUser={false} reviews={courierData?.reviews_received} />
        </div>
    );
};

export default SendParcelCourierPage;
