import React from 'react';
import Navigation from '../Navigation/Navigation';
import styles from './styles.module.css'
import requestRejectedIcon from '../../assets/icons/emty.svg'
import {useTranslation} from "react-i18next";

const EmptyTracking = () => {

  const { t } = useTranslation();



  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.img}>
          <img src={requestRejectedIcon} alt="requestRejectedIcon"/>
        </div>
        <h2>{t('request_rejected_page.title')}<br/> {t('request_rejected_page.title2')}</h2>
        <p>{t('request_rejected_page.description')}</p>
      </div>

    </>
  )
}

export default EmptyTracking;
