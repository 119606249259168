import React, { useEffect, useState, useCallback, useMemo } from 'react';
import DatePicker from 'react-mobile-datepicker';
import moment from 'moment';
import styles from './styles.module.css';
import ArrowRight from './../../assets/icons/arrowRight.svg';
import ArrowRightActive from './../../assets/icons/arrowRightBlue.svg';
import { useTranslation } from 'react-i18next';

interface IProps {
  initialDate?: any; // Уточните тип, если возможно
  onDateChange: (date: string) => void;
}

function parseDate(dateStr: string): Date | string {
  const format1 = /^\d{2}\.\d{2}\.\d{4}$/;
  const format2 = /^\d{4}-\d{2}-\d{2}$/;
  let date: Date | string;

  if (format1.test(dateStr)) {
    const [day, month, year] = dateStr.split('.');
    date = new Date(Number(year), Number(month) - 1, Number(day));
  } else if (format2.test(dateStr)) {
    date = new Date(dateStr);
  } else {
    return dateStr; // Возвращаем строку, если формат не распознан
  }
  return date;
}

const CustomDatePicker: React.FC<IProps> = React.memo(({ initialDate, onDateChange }) => {
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState<string>(initialDate || '');
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  const handleSelect = useCallback((date: string) => {
    const newDate = moment(date).format('YYYY-MM-DD');
    setCurrentDate(newDate);
    onDateChange(newDate);
    handleToggle();
  }, [onDateChange, handleToggle]);

  const monthMap = useMemo(() => ({
    '1': t('custom_date_picker.months.january'),
    '2': t('custom_date_picker.months.february'),
    '3': t('custom_date_picker.months.march'),
    '4': t('custom_date_picker.months.april'),
    '5': t('custom_date_picker.months.may'),
    '6': t('custom_date_picker.months.june'),
    '7': t('custom_date_picker.months.july'),
    '8': t('custom_date_picker.months.august'),
    '9': t('custom_date_picker.months.september'),
    '10': t('custom_date_picker.months.october'),
    '11': t('custom_date_picker.months.november'),
    '12': t('custom_date_picker.months.december'),
  }), [t]);

  const dateConfig = useMemo(() => ({
    'date': {
      format: 'DD',
      caption: t('custom_date_picker.caption.day'),
      step: 1,
    },
    'month': {
      format: value => monthMap[value.getMonth() + 1],
      caption: t('custom_date_picker.caption.month'),
      step: 1,
    },
    'year': {
      format: 'YYYY',
      caption: t('custom_date_picker.caption.year'),
      step: 1,
    },
  }), [monthMap, t]);

  const currentValue = useMemo(() => {
    return initialDate ? parseDate(initialDate) : new Date(1991, 1, 1);
  }, [initialDate]);

  return (
    <div className="App">
      <div className={styles.dateBlock} onClick={handleToggle}>
        <p className={styles.textDate}>{t('custom_date_picker.date_of_birth')}</p>
        <div className={styles.wrapperDate}>
          <p className={isOpen ? styles.numDateActive : styles.numDate}>
            {currentDate && moment(currentDate).format('DD.MM.YYYY')}
          </p>
          <img src={isOpen ? ArrowRightActive : ArrowRight} alt="" />
        </div>
      </div>
      <p className={styles.text}>{t('custom_date_picker.info')}</p>
      <DatePicker
        dateConfig={dateConfig}
        headerFormat="DD/MM/YYYY"
        min={new Date(1901, 0, 1)}
        max={moment().subtract(18, 'years').toDate()}
        showCaption
        showHeader
        value={currentValue}
        theme={'ios'}
        isOpen={isOpen}
        onSelect={handleSelect}
        onCancel={handleToggle}
        confirmText={t('custom_date_picker.confirm')}
        cancelText={t('custom_date_picker.cancel')}
      />
    </div>
  );
});

export default CustomDatePicker;
