import React, { useEffect, useState } from 'react';

import xsParcelImage from '../../assets/images/xsParcel.png';
import sParcelImage from '../../assets/images/sParcel.png';
import mParcelImage from '../../assets/images/mParcel.png';
import lParcelImage from '../../assets/images/lParcel.png';

import { Button } from '../../components/Button/Button';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '../../utils/useQuery';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';
import { ROUTE_PATHS } from '../../const/routes';
import { parcelPricesTemp } from '../TrackingSizePriceSelect';
import { SessionStorageService } from '../../utils/SessionStorageService';

const convertToSymbolsArray = (items: any): string[] | null => {
  if (!items) return null
  return items.map(item => item.symbol);
};

export const getPrice = (size: string) => {

  const defaultPrice = parcelPricesTemp.find(item => item.title == size)
  return defaultPrice?.price
}
const TrackingSizeSelect = () => {
  const query = useQuery();
  const id = query.get('id');
  const navigate = useNavigate();
  const location = useLocation()

  const {t} = useTranslation();

  const PARCEL_SIZES_CONFIG = [
    {
      id: 1,
      name: t('parcel_size_select_page.documents'),
      description: '',
      image: xsParcelImage,
      value: 'Documents',
    },
    {
      id: 2,
      name: t('parcel_size_select_page.s_size.title'),
      description: t('parcel_size_select_page.s_size.description'),
      image: sParcelImage,
      value: 'S',
    },
    {
      id: 3,
      name: t('parcel_size_select_page.m_size.title'),
      description: t('parcel_size_select_page.m_size.description'),
      image: mParcelImage,
      value: 'M',
    },
    {
      id: 4,
      name: t('parcel_size_select_page.l_size.title'),
      description: t('parcel_size_select_page.l_size.description'),
      image: lParcelImage,
      value: 'L',
    },
  ]

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const onChange = (event: any) => {
    const value = event.target.value;

    setSelectedValues((prevState) => {
      const newSelectedValues = [...prevState];

      const selectedIndex = PARCEL_SIZES_CONFIG.findIndex(item => item.value === value);

      if (!newSelectedValues.includes(value)) {
        newSelectedValues.push(value);
        for (let i = 0; i < selectedIndex; i++) {
          const previousValue = PARCEL_SIZES_CONFIG[i].value;
          if (!newSelectedValues.includes(previousValue)) {
            newSelectedValues.push(previousValue);
          }
        }
      } else {
        newSelectedValues.splice(newSelectedValues.indexOf(value), 1);
      }

      return newSelectedValues;
    });
  };

  const onSelectClick = () => {
    if (selectedValues.length) {
      const deliverParcelSizes = selectedValues.map(item => {
        const currentItem = PARCEL_SIZES_CONFIG.find(i => i.value === item)
        const symbol = currentItem?.value as string
        return {
          id: currentItem?.id,
          name: currentItem?.name,
          symbol: symbol,
          price: location?.state?.deliverParcelSizes.hasOwnProperty(symbol) ? location?.state?.deliverParcelSizes[symbol] : getPrice(symbol)

        }
      })

      SessionStorageService.save('sizes', deliverParcelSizes)
      navigate(`${ROUTE_PATHS.TRACKING_SIZE_PRICE_SELECT}?id=${id}`, {
        state: {
          ...location?.state,
          deliverParcelSizes: deliverParcelSizes
        }
      });
    }
  };
  useEffect(() => {
    const restoredState = SessionStorageService.get<any>('sizes');

    if (location?.state?.deliverParcelSizes) {
      const sizes = location.state.deliverParcelSizes;
      const keysArray = Object.keys(sizes)
      const keysArrayFromBack = convertToSymbolsArray(restoredState)
      setSelectedValues(keysArrayFromBack ? keysArrayFromBack : keysArray);
    }
  }, []);

  return (
    <div className={styles.globalWrapper}>
      <div className={styles.pageWrapper}>
        <div className={styles.parcelItems}>
          {PARCEL_SIZES_CONFIG.map((item) => (
            <label
              key={item.id}
              htmlFor={item.value}
              className={`${styles.parcelSizeItem} ${selectedValues.includes(item.value) ? styles.active : ''}`}
            >
              <div className={styles.parcelItem}>
                <img src={item.image} alt={item.name} height={100} width={100}/>
                <div className={styles.parcelInfo}>
                  <p>{item.name}</p>
                  <p>{item.description}</p>
                  <div className={`${styles.inputWrapper} ${selectedValues.includes(item.value) ? styles.active : ''}`}>
                    <input
                      id={item.value}
                      onChange={onChange}
                      checked={selectedValues.includes(item.value)}
                      value={item.value}
                      type="checkbox"
                      name="size"
                    />
                  </div>
                </div>
              </div>
            </label>
          ))}
        </div>
      </div>
      <div className={styles.bottomBlock}>
        <Button color="fill" text={t('tracking_size_select.continue_button')} size="large" onClick={onSelectClick}
                disabled={selectedValues.length === 0}/>
      </div>
    </div>
  );
};

export default TrackingSizeSelect;
