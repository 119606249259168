import styles from "./styles.module.css";
import {useTranslation} from "react-i18next";
export enum EParcelStatus {
  WAITING_FOR_APPROVAL = 1,
  APPROVED = 2,
  DELIVERED_GIVE_FEEDBACK = 3,
  DELIVERED = 4,
}
interface IProps {
  status: EParcelStatus;
}
export function mapParcelStatus(status: string): EParcelStatus | undefined {
  switch (status) {
    case "Requested":
      return EParcelStatus.WAITING_FOR_APPROVAL;
    case "Approved":
      return EParcelStatus.APPROVED;
    case "Delivered (Feedback Pending)":
      return EParcelStatus.DELIVERED_GIVE_FEEDBACK;
    case "Delivered":
      return EParcelStatus.DELIVERED;
    default:
      return undefined;
  }
}
const ParcelStatus: React.FC<IProps> = ({ status }) => {
  const { t } = useTranslation();
  const getParams = () => {
    switch (status) {
      case EParcelStatus.APPROVED:
        return {
          bgColor: "#E1F7E6",
          color: "#34C759",
          text: t('parcel_card.status.approved'),
        };
      case EParcelStatus.WAITING_FOR_APPROVAL:
        return {
          bgColor: "#FFFAE6",
          color: "#FFCC00",
          text: t('parcel_card.status.waiting_approval'),
        };
      case EParcelStatus.DELIVERED_GIVE_FEEDBACK:
        return {
          bgColor: "#DDE8FF",
          color: "#007AFF",
          text: t('parcel_card.status.delivered_give_feedback'),
        };
      case EParcelStatus.DELIVERED:
        return { bgColor: "#DDE8FF", color: "#007AFF",  text: t('parcel_card.status.delivered'), };
    }
  };
  const current = getParams();
  return (
    <p
      className={styles.status}
      style={{
        backgroundColor: current.bgColor,
        color: current.color,
      }}
    >
      {current.text}
    </p>
  );
};
export default ParcelStatus;
