import React, { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { tg } from '../../App';
import { ROUTE_PATHS } from '../../const/routes';
import { useModal } from '../../context/ModalContext';
import { saveHashedTgIdToLocalStorage } from '../../utils/storeActions';
import { SessionStorageService } from '../../utils/SessionStorageService';

const tgBackButtonParams: any = {
  hide: [
    ROUTE_PATHS.HOME,
    ROUTE_PATHS.PROFILE,
    ROUTE_PATHS.PARCEL_SEND,
    ROUTE_PATHS.TRACKING,
    ROUTE_PATHS.PARCEL_DELIVER,
  ],
  show: [
    {pathName: ROUTE_PATHS.HOME, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.PROFILE, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.USER_INFO, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.EDIT_PROFILE, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.PHOTO_PAGE, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.ADDITIONAL_INFO, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.PARCEL_SEND, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.SELECT_DATE_RANGE, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.SELECT_LOCATION, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.PARCEL_SIZE_SELECT, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.PARCEL_SEND_SEARCH_RESULTS, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.PARCEL_SEND_SEARCH_RESULT, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.PARCEL_SEND_SEARCH_RESULT_COURIER, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.TRACKING, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.TRACKING_ARCHIVED, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.SENDER_DELIVER_DETAIL_PAGE, goBack: -1, condition: true},
    {pathName: ROUTE_PATHS.COURIER_DELIVER_DETAIL_PAGE, goBack: ROUTE_PATHS.TRACKING, condition: true},
    {pathName: ROUTE_PATHS.GIVE_FEEDBACK_PAGE, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.EDIT_INFO_PAGE, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.TRACKING_SIZE_SELECT, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.ADDITIONAL_INFO_DETAIL_PAGE, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.TRACKING_SIZE_PRICE_SELECT, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.PARCEL_DELIVER, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.DELIVER_SELECT_LOCATION, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.DELIVER_PARCEL_DATE_RANGE, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.DELIVER_PARCEL_SIZE_SELECT, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.DELIVER_PARCEL_PRICE_SELECT, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.DELIVER_PARCEL_SIZE_SELECT_EDIT, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.DELIVER_PARCEL_PRICE_SELECT_EDIT, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.DELIVER_PROVIDE_MORE_INFO, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.CHECK_YOUR_DELIVER, goBack: -1, condition: null},
    {pathName: ROUTE_PATHS.POSSIBLE_EARNINGS, goBack: -1, condition: null},
  ],
};

const PathListener: React.FC = React.memo(() => {
  const {closeModal} = useModal()
  const [searchParams] = useSearchParams();
  const hashedtgId = searchParams.get('hashed-tg-id');
  const tgId = searchParams.get('tg-id');
  const fromBot = searchParams.get('fromBot');
  const tgBtn = tg.BackButton;
  const navigate = useNavigate();
  const location = useLocation();
  const isHandlerSet = useRef(false);

  const handlePathChange = useCallback((pathname: string) => {
    closeModal();
    window.scrollTo(0, 0);
    tgBtn.offClick();
    if (tgBackButtonParams.hide.includes(pathname)) {
      tgBtn.hide();
    } else if (tgBackButtonParams.show.find(item => item.pathName === pathname)?.condition && fromBot) {
      tgBtn.offClick();
      tgBtn.show();
      if (!isHandlerSet.current) {
        tgBtn.onClick(() => {
          navigate(ROUTE_PATHS.TRACKING);
          tgBtn.offClick();
        });
        isHandlerSet.current = true;
      }
    } else {
      tgBtn.offClick();
      tgBtn.show();
      if (!isHandlerSet.current) {
        tgBtn.onClick(() => {
          navigate(-1);
          tgBtn.offClick();
        });
        isHandlerSet.current = true;
      }
    }
  }, []);

  useEffect(() => {
    handlePathChange(location.pathname);
  }, [location.pathname]);

  useEffect(() => {


    if (hashedtgId && hashedtgId !== 'null') {
      saveHashedTgIdToLocalStorage(hashedtgId);
    }
    if (tgId && tgId !== 'null') {
      SessionStorageService.save('tgId', tgId)
    }
  }, [hashedtgId, tgId]);

  return null;
});

export default PathListener;
