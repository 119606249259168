import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import RemoveIcon from "../../assets/icons/removePhoto.svg";
import { useTranslation } from "react-i18next";

interface PhotoProps {
  photo: string;
  onRemove?: () => void;
}

export const PhotoDisplay: React.FC<PhotoProps> = ({ photo, onRemove }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlePhotoClick = () => {
    // Перенаправляємо користувача на PhotoPage і передаємо фото через state
    navigate('/photo-page', { state: { photo } });
  };

  return (
    <div className={styles.photoContainer} onClick={handlePhotoClick}>
      <img src={photo} alt="Photo" className={styles.photo} />

      {onRemove && (
        <img
          src={RemoveIcon}
          alt={t('photo_display.delete')}
          onClick={(e) => {
            e.stopPropagation(); // Щоб не спрацьовував handlePhotoClick при кліку на іконку видалення
            onRemove();
          }}
          className={styles.removePhoto}
        />
      )}
    </div>
  );
};
