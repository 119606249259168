import axios, { AxiosInstance } from 'axios';
import globalRequestStore from '../../store';

export const BASE_SCHEME = 'https'
export const BASE_ORIGIN = 'admin.delivermypack.online'
export const BASE_URL = `${BASE_SCHEME}://${BASE_ORIGIN}`

const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
axiosInstance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    if (error.response.data.errors) {
      // globalRequestStore.setError(error.response.data.errors)
      throw new Error(error.response.data.errors);
    }
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response);
    } else {
      throw error;
    }
  },
);

export { axiosInstance };
