import CustomDateRangeSelect from '../pages/CustomDateRangeSelect/DateRangeSelect';

export const ROUTE_PATHS = {
  HOME: '/',
  PLUG: '/plug',
  PROFILE: '/profile',
  USER_INFO: '/user-info',
  EDIT_PROFILE: '/profile/edit',
  PHOTO_PAGE: '/photo-page',
  TERMS_OF_USE_PAGE: '/terms-of-use',
  PRIVACY_POLICY_PAGE: '/privacy-policy',

  ADDITIONAL_INFO: '/additional-info',

  PARCEL_SEND: '/parcel-send',
  SELECT_DATE_RANGE: '/parcel-send/select-date-range',
  SELECT_LOCATION: '/parcel-send/select-location',
  CUSTOM_SELECT_LOCATION: '/select-location',
  CUSTOM_DATE_RANGE_SELECT: '/date-range-edit',
  PARCEL_SIZE_SELECT: '/parcel-send/select-parcel-size',
  PARCEL_SEND_SEARCH_RESULTS: '/parcel-send/results',
  PARCEL_SEND_SEARCH_RESULT: '/parcel-send/results/:id',
  PARCEL_SEND_SEARCH_RESULT_COURIER: '/parcel-send/courier/:id',

  TRACKING: '/tracking',
  TRACKING_ARCHIVED: '/tracking/archived',
  SENDER_DELIVER_DETAIL_PAGE: '/tracking/sender-deliver-detail',
  COURIER_DELIVER_DETAIL_PAGE: '/tracking/courier-deliver-detail',
  GIVE_FEEDBACK_PAGE: '/tracking/give-feedback',
  EDIT_INFO_PAGE: '/tracking/edit-info-page',
  TRACKING_SIZE_SELECT: '/tracking/tracking-size-select',
  ADDITIONAL_INFO_DETAIL_PAGE: '/tracking/additional-info-detail-page',
  TRACKING_SIZE_PRICE_SELECT: '/tracking/tracking-size-price-select',

  PARCEL_DELIVER: '/parcel-deliver',
  DELIVER_SELECT_LOCATION: '/parcel-deliver/select-location',
  DELIVER_PARCEL_DATE_RANGE: '/parcel-deliver/select-date-range',
  DELIVER_PARCEL_SIZE_SELECT: '/parcel-deliver/select-parcel-size',
  DELIVER_PARCEL_PRICE_SELECT: '/parcel-deliver/select-parcel-price',
  DELIVER_PARCEL_SIZE_SELECT_EDIT: '/parcel-deliver/select-parcel-size/edit',
  DELIVER_PARCEL_PRICE_SELECT_EDIT: '/parcel-deliver/select-parcel-price/edit',
  DELIVER_PROVIDE_MORE_INFO: '/parcel-deliver/provide-more-info',
  CHECK_YOUR_DELIVER: '/parcel-deliver/check-your-deliver',
  POSSIBLE_EARNINGS: '/parcel-deliver/check-your-deliver/possible-earnings',
} as const;
