import React from 'react';
import styles from './styles.module.css'

interface IProps {
  onClose: () => void;
  onDeleteRequest: () => void;
}

const DeleteRequestModal: React.FC<IProps> = (props) => {


  const handleClick = () => {
    props.onDeleteRequest()
    props.onClose()
  }
  return (
    <div className={styles.cover}>
      <div className={styles.modal}>
        <h3 className={styles.modal}>Delete request</h3>
        <p>
          Are you sure you want to delete the request? You will be returned to the main screen.
        </p>
        <div className={styles.buttons}>
          <button
            onClick={handleClick}
            className={styles.delete}
          >
            Delete
          </button>
          <button onClick={props.onClose} className={styles.cancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}
export default DeleteRequestModal
