import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import ParcelCard from '../../components/ParcelCard';
import DeliveryTripCard from '../../components/DeliveryTripCard';
import { mapParcelStatus } from '../../components/ParcelCard/ParcelStatus';
import { useNavigate } from 'react-router-dom';
import {
  getArchivedTrips,
  getArchiveParcels,
  Parcel,
  Trip
} from '../../service/tracking/tracking';
import EmptyTracking from '../../components/EmptyTracking';
import { ROUTE_PATHS } from '../../const/routes';


const ArchivedTrackingPage: React.FC<{}> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [archivedParcels, setArchivedParcels] = useState<Parcel[]>([]);
  const [archivedTrips, setArchivedTrips] = useState<Trip[]>([]);

  useEffect(() => {
    getArchiveParcels().then(({results}) => {
      results?.length && setArchivedParcels(results)
    })
    getArchivedTrips().then(({results}) => {
      results?.length && setArchivedTrips(results)
    })
  }, []);


  const handleNavigateToSenderInfo = (id) => navigate(`${ROUTE_PATHS.SENDER_DELIVER_DETAIL_PAGE}?id=${id}`);
  const handleNavigateToCourierInfo = (id) => navigate(`${ROUTE_PATHS.COURIER_DELIVER_DETAIL_PAGE}?id=${id}`);

  return (
    <div className={styles.wrapper}>
      {archivedParcels.length > 0 && <>
          <div className={styles.tracking}>
              <p className={styles.title}>{t('archived_tracking_page.parcels_title')}</p>
          </div>
          <div className={styles.parcelCards}>
            {archivedParcels?.map((parcel, index) => (
              <ParcelCard
                key={index}
                avatarImg={parcel.courier.photo_link}
                name={parcel.courier.name}
                dateRange={{from: parcel.dispatch_period_start, to: parcel.dispatch_period_end}}
                rating={parcel.courier.rating}
                place={{from: parcel.departure_point, to: parcel.destination_point}}
                status={mapParcelStatus(parcel.status)}
                onClick={() => handleNavigateToSenderInfo(parcel.id)}
              />
            )).reverse()}
          </div>
      </>}

      {archivedTrips && archivedTrips.length>0 &&
          <>
              <p className={styles.title}>{t('archived_tracking_page.delivery_trips_title')}</p>
              <div className={styles.deliveryTripCards}>
                {archivedTrips?.map((data, index) => (
                  <DeliveryTripCard
                    key={index}
                    dateRange={{from: data.dispatch_period_start, to: data.dispatch_period_end}}
                    place={{from: data.departure_point, to: data.destination_point}}
                    // status={data.status}
                    requests={{max: data.requests.requested, current: data.requests.approved}}
                    onClick={() => handleNavigateToCourierInfo(data.id)}
                  />
                )).reverse()}
              </div>
          </>}
      {archivedTrips.length < 1 && archivedParcels.length < 1 && <EmptyTracking/>}
    </div>
  );
};

export default ArchivedTrackingPage;
