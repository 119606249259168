import React, {FC} from 'react';
import style from "./dialog.module.css";
import {useTranslation} from "react-i18next";

interface DialogProps {
    onSubmit: () => any;
}

const UnavailableCountryDialog: FC<DialogProps> = ({ onSubmit, }) => {
    const { t } = useTranslation();
    return (
        <div className={style.cover}>
            <div className={style.deleteModal}>
                <p>
                    {t('service_not_available.message')}
                </p>
                <div className={style.buttons}>
                    <button
                        onClick={onSubmit}
                        className={style.delete}
                    >
                        Ok
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UnavailableCountryDialog;