import { axiosInstance } from '../axiosConfig/axiosConfig';
import { getHashedTgIdFromLocalStorage, getProfileFromLocalStorage } from '../../utils/storeActions';
import globalRequestStore from '../../store';
import { addProtocol } from '../../utils/addProtocol';

export interface UserProfile {
  name: string;
  sex: string;
  birthdate: string | null;
  bio: string;
  social_link: string;
  photo_link: string;
  reviews_received: any[];
  rating?: string;
}



export async function getUserInfo(id: number): Promise<UserProfile | any> {
  const tgId = getHashedTgIdFromLocalStorage();
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.get(
      `/api/users/${id}`,
      {
        headers: {
          'tg-id': tgId
        }
      }
    );
    globalRequestStore.finishLoading();
    return response.data;
  } catch (error: any) {
    globalRequestStore.setError(error.message);
  }
}

export async function updateUserProfile(id: any, editProfile: any): Promise<any> {
  const tgId = getHashedTgIdFromLocalStorage();
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.put(
      `/api/users/${id}/`,
      {
        ...editProfile,
        social_link: addProtocol(editProfile.social_link),
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'tg-id': tgId
        }
      }
    );

    globalRequestStore.finishLoading();
    return response.data;
  } catch (error: any) {
    return {error: true}
    //TODO temp solution
    // if (error.keys) globalRequestStore.setError(error);
  }
}
export async function updateLangProfile(id: any, lang: any): Promise<any> {
  const tgId = getHashedTgIdFromLocalStorage();
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.put(
      `/api/users/${id}/`,
      {
        language_code: lang
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'tg-id': tgId
        }
      }
    );
    globalRequestStore.finishLoading();
    return response.data;
  } catch (error: any) {
    //TODO temp solution
    if (error.keys) globalRequestStore.setError(JSON.stringify(error));
  }
}
