export function addProtocol(url: string) {
  if (!url) return null
  if (!/^https?:\/\//i.test(url)) {
    return "https://" + url;
  }
  return url;
}
export function removeProtocol(url?: string ) {
  if (!url) return null;
  return url.replace(/^https?:\/\//i, '');
}
