import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ProfileProvider } from './context/ProfileContext';
import { ReviewsProvider } from './context/ReviewsContext';
import { SendParcelProvider } from './context/SendParcelContext';
import { ModalProvider } from './context/ModalContext';

import { I18nextProvider } from 'react-i18next';
import { ToastProvider } from './context/ToastContext';
import i18n from './i18n/index';

import ErrorBoundary from './utils/ErrorBoundary';
import GlobalLoaderAndError from './components/GlobalLoaderAndError';
import { RecentSearchProvider } from './context/RecentSearchContext';
import { PhotosProvider } from './context/PhotosContext';
import { DeliverParcelProvider } from './context/DeliverParcelContext';
console.warn = () => {};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ProfileProvider>
      <ReviewsProvider>
        <SendParcelProvider>
          <DeliverParcelProvider>
            <RecentSearchProvider>
              <PhotosProvider>
                <ToastProvider>
                  <ModalProvider>
                    <I18nextProvider i18n={i18n}>
                      <GlobalLoaderAndError/>
                      <App/>
                    </I18nextProvider>
                  </ModalProvider>
                </ToastProvider>
              </PhotosProvider>
            </RecentSearchProvider>
          </DeliverParcelProvider>
        </SendParcelProvider>
      </ReviewsProvider>
    </ProfileProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
