import React from 'react';
import styles from './styles.module.css'
import {useTranslation} from "react-i18next";

interface IProps {
  onClose: () => void;
  onApproveRequest: () => void;
}

const ApproveRequestModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const handleClick = () => {
    props.onApproveRequest()
    props.onClose()
  }
  return (
    <div className={styles.cover}>
      <div className={styles.modal}>
        <h3 className={styles.modal}>{t('approve_request_modal.title')}</h3>
        <p>
          {t('approve_request_modal.description')}
        </p>
        <div className={styles.buttons}>
          <button
            onClick={handleClick}
            className={styles.delete}
          >
            {t('approve_request_modal.approve_button')}

          </button>
          <button onClick={props.onClose} className={styles.cancel}>
            {t('approve_request_modal.cancel_button')}

          </button>
        </div>
      </div>
    </div>
  )
}
export default ApproveRequestModal
