import React, {FC} from 'react';
import style from "./dialog.module.css";
import {useTranslation} from "react-i18next";

interface DialogProps {
    onSubmit: () => any;
    onCancel: () => any;
    title: string;
    additionalText: string;
}

const Dialog: FC<DialogProps> = ({ onCancel, additionalText, onSubmit, title }) => {
    const { t } = useTranslation();
    return (
        <div className={style.cover}>
            <div className={style.deleteModal}>
                <h3 className={style.popupTitle}>{title}</h3>
                <p>
                    {additionalText}
                </p>
                <div className={style.buttons}>
                    <button
                        onClick={onSubmit}
                        className={style.delete}
                    >
                        {t('dialog.delete_button')}
                    </button>
                    <button onClick={onCancel} className={style.cancel}>
                        {t('dialog.cancel_button')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Dialog;