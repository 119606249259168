import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';
import done from '../assets/icons/doneGreen.svg'
import close from '../assets/icons/close.svg'

type ToastType = 'accept' | 'decline';

export enum EToast {
  ACCEPT = 'accept',
  DECLINE = 'decline'
}

interface ToastContextType {
  showToast: (message: string, type: ToastType) => void;
  closeToast: () => void;
  showMultiToast: (data: ToastState[]) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

interface ToastProviderProps {
  children: ReactNode;
}

interface ToastState {
  message: string;
  type: ToastType;
}


const Toast: React.FC<{ message: string, type: ToastType, onClose: () => void }> = ({message, type, onClose}) => {
  const backgroundColor = type === 'accept' ? '#E1F7E6' : '#FBE1E1';
  const icon = type === 'accept' ? done : close;

  useEffect(() => {
    const timer = setTimeout(onClose, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (

    <div
      style={{
        backgroundColor,
        width: '100%',
        padding: '10px',
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        boxSizing: 'border-box',
        minHeight: '54px',
        maxWidth: '100%',
        boxShadow: '0px 4px 14px 0px #34343421',

        flex: 1,
      }}
    >
      <img src={icon} alt="icon" style={{height: '20px', width: '20px'}}/>
      <p
        style={{
          fontSize: 16,
          fontWeight: '400',
          lineHeight: '21px',
          letterSpacing: '-0.23px',
          textAlign: 'left',
          margin: 0,
          flex: 1,
        }}
      >
        {message}
      </p>
    </div>

  );
};

const ToastWrapper: React.FC<ToastProviderProps> = ({children}) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 4,
        left: 16,
        right: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 4,
      }}
    >
      {children}
    </div>
  )
}
export const ToastProvider: React.FC<ToastProviderProps> = ({children}) => {
  const [toast, setToast] = useState<ToastState | null>(null);
  const [multiToast, setMultiToast] = useState<ToastState[] | any>(null);

  const showToast = useCallback((message: string, type: ToastType) => {
    setToast({message, type});
  }, []);

  const showMultiToast = useCallback((data: ToastState[]) => {
    setMultiToast(data)
  }, []);

  const closeToast = useCallback(() => {
    setToast(null);
  }, []);
  const closeToastByMessage = useCallback((message) => {
    setMultiToast(prevState => prevState.filter(item => item.message !== message))
  }, []);
  return (
    <ToastContext.Provider value={{showToast, closeToast, showMultiToast}}>
      <>
        {children}

        <ToastWrapper>
          <>
            {toast && <Toast message={toast.message} type={toast.type} onClose={closeToast}/>}
            {multiToast && multiToast.map(item => <Toast message={item.message} type={item.type}
                                                         onClose={() => closeToastByMessage(item.message)}/>)}
          </>
        </ToastWrapper>

      </>
    </ToastContext.Provider>
  );
};


export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
