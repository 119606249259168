import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const PhotoPage: React.FC = () => {
  const location = useLocation();
  const { photo } = location.state || {};
  const [scale, setScale] = useState(1);

  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    const newScale = scale + e.deltaY * -0.01;
    setScale(Math.min(Math.max(1, newScale), 3));
  };

  if (!photo) {
    return <p>No photo provided</p>;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
        background: '#000'
      }}
      onWheel={handleWheel}
    >
      <img
        src={photo}
        alt="Zoomable"
        style={{

          transform: `scale(${scale})`,
          width: '100%',
          height: 'auto',
          objectFit: 'contain',
          transition: 'transform 0.2s ease-out'
        }}
      />
    </div>
  );
};

export default PhotoPage;
