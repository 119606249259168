import React from "react";
import styles from './styles.module.css'
import { PhotoDisplay } from "../PhotoDisplay";
import { useTranslation } from 'react-i18next';

interface IProps {
  data: any;
}
const PhotoDisplayList: React.FC<IProps> = ({data}) => {
  const {t} = useTranslation();
  return (
    <>
      {data.length > 0 && <p className={styles.photosTitle}>{t('additional_info_page.photos_title')}</p>}
      <div className={styles.wrapper}>
        {data.map((photo) => (
          <PhotoDisplay photo={photo?.photo_link || photo?.photo} />
        ))}
      </div>
    </>

  );
};
export default PhotoDisplayList;
