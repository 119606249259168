import React, { useRef, useEffect } from 'react';

interface TextAreaProps {
  name?: string;
  id?: string;
  value?: string;
  onChange?: any;
  placeholder?: string;
  maxLength?: number;
  rows?: number;
  className?: string;
  onFocus?: any;
  minHeight?: any;

}

const TextArea: React.FC<TextAreaProps> = ({
                                             name,
                                             id,
                                             value = '',
                                             onChange = () => {},
                                             placeholder,
                                             maxLength ,
                                             rows = 1,
                                             className,
                                             onFocus,
                                             minHeight
                                           }) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    target.style.height = 'auto';
    target.style.height = `${target.scrollHeight}px`;
    // target.scrollTop = target.scrollHeight;
  };

  // Устанавливаем высоту текстовой области при монтировании компонента
  useEffect(() => {

    if (textAreaRef.current) {
      if (minHeight){
        textAreaRef.current.style.minHeight = `${minHeight}px`;
      }
      textAreaRef.current.style.height = 'auto'; // Сбрасываем высоту
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Устанавливаем высоту в зависимости от содержимого
      // textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;

    }
  }, [value]); // Запускаем эффект при изменении value

  return (
    <textarea
      onFocus={onFocus}
      name={name}
      id={id}
      rows={rows}
      style={{
        width: '100%',
        overflow: 'hidden',
        resize: 'none',
      }}
      onInput={handleInput}
      ref={textAreaRef}
      value={value}
      onChange={(event) => {
        handleInput(event);
        onChange(event.target.value.substring(0, maxLength));
      }}
      className={className}
      placeholder={placeholder}
    ></textarea>
  );
};

export default TextArea;
