import React from 'react';
import styles from './styles.module.css';
import ParcelStatus, { EParcelStatus } from './ParcelStatus';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/arrowRight.svg';
import moment from 'moment';
import Avatar, { AvatarSizes } from '../Avatar/Avatar';

interface IProps {
  avatarImg?: string;
  name: string;
  dateRange?: { from: string; to: string };
  place?: { from: string; to: string };
  rating?: string | null | undefined | number;
  status?: EParcelStatus;
  onClick?: () => void;
  size?: string;
  price?: number;
}

const ParcelCard: React.FC<IProps> = (props) => {
  console.log(props,'props')
  const getFormat = (date: string) => moment(date).format('DD.MM');
  return (
    <div className={styles.wrapper} onClick={props.onClick}>
      <div className={styles.content} style={{alignItems: `${props.place ? 'flex-start' : 'center'}`}}>
        <div className={styles.about}>
          <Avatar avatarUrl={props.avatarImg} profileName={props.name} size={AvatarSizes.s}/>
          {/*{props.avatarImg && <img src={props.avatarImg} alt="avatar"/>}*/}
          <div className={styles.info}>
            <p className={styles.name}>
              <p className={styles.nameText}>{props.name}</p>
              {props.rating && (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 8,
                  minWidth: 50,
                }} >
                  <StarIcon/>
                  <p className={styles.rating}>{props.rating}</p>
                </div>
              )}
            </p>
            {props.place && (
              <p className={styles.place}>
                {props.place.from} - {props.place.to}
              </p>
            )}
            {props.dateRange && (
              <p className={styles.date}>
                {getFormat(props.dateRange.from)} -{' '}
                {getFormat(props.dateRange.to)}
              </p>
            )}
          </div>
        </div>
        <div className={styles.action}>
          {props.size && <p className={styles.size}>{props.size && `${props.size}`}</p>}

          {props.price && <p className={styles.size}>{props.price && `${props.price}$`}</p>}
          <RightArrow/>
        </div>
      </div>
      {props.status && <ParcelStatus status={props.status}/>}
    </div>
  );
};
export default ParcelCard;
