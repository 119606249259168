import React, { useEffect, useLayoutEffect, useState } from 'react';
import Navigation from '../../components/Navigation/Navigation';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import ParcelCard from '../../components/ParcelCard';
import DeliveryTripCard from '../../components/DeliveryTripCard';
import { mapTripStatus } from '../../components/DeliveryTripCard/DeliveryTripStatus';
import { mapParcelStatus } from '../../components/ParcelCard/ParcelStatus';
import { useNavigate } from 'react-router-dom';
import {
  getActiveParcels,
  getActiveTrips, getArchiveStatus,
  Parcel,
  Trip
} from '../../service/tracking/tracking';
import EmptyTracking from '../../components/EmptyTracking';
import globalRequestStore from '../../store';
import { observer } from 'mobx-react-lite';
import { ROUTE_PATHS } from '../../const/routes';


const TrackingPage: React.FC<{}> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [activeParcels, setActiveParcels] = useState<Parcel[]>([]);
  const [activeTrips, setActiveTrips] = useState<Trip[]>([]);
  const [loading,setLoading] = useState(false)
  const [isArchiveEmpty,setIsArchiveEmpty] = useState(false)
  useLayoutEffect(()=>{
    globalRequestStore.startLoading()
  },[])
  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        const activeParcelsData = await getActiveParcels();
        if (activeParcelsData?.results?.length) {
          setActiveParcels(activeParcelsData.results);
        }

        const archiveStatusData = await getArchiveStatus();
        setIsArchiveEmpty(archiveStatusData)


        const activeTripsData = await getActiveTrips();
        if (activeTripsData?.results?.length) {
          setActiveTrips(activeTripsData.results);
        }
        globalRequestStore.finishLoading()
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleNavigateToArchived = () => navigate(ROUTE_PATHS.TRACKING_ARCHIVED);

  const handleNavigateToSenderInfo = (id) => navigate(`${ROUTE_PATHS.SENDER_DELIVER_DETAIL_PAGE}?id=${id}`);
  const handleNavigateToCourierInfo = (id) => navigate(`${ROUTE_PATHS.COURIER_DELIVER_DETAIL_PAGE}?id=${id}`);
  const isEmpty = activeTrips?.length < 1 && activeParcels?.length < 1

  if (globalRequestStore.isLoading) return  <Navigation/>
  return (
    <div className={styles.wrapper}>


      {isArchiveEmpty && <p className={styles.btn} onClick={handleNavigateToArchived}>
        {t('tracking_page.archived')}
      </p>}
      {isEmpty && <div style={{height: 16}}/>}

      {activeParcels?.length > 0 &&
          <div className={styles.tracking}>
              <p className={styles.title}>{t('tracking_page.parcels_title')}</p>
              <div className={styles.parcelCards}>
                {activeParcels?.map((parcel, index) => (
                  <ParcelCard
                    key={index}
                    avatarImg={parcel.courier.photo_link}
                    name={parcel.courier.name}
                    //@ts-ignore
                    price={parcel.price_detail}
                    dateRange={{from: parcel.dispatch_period_start, to: parcel.dispatch_period_end}}
                    rating={parcel.courier.rating}
                    place={{from: parcel.departure_point, to: parcel.destination_point}}
                    status={mapParcelStatus(parcel.status)}
                    onClick={() => handleNavigateToSenderInfo(parcel.id)}
                  />
                )).reverse()}
              </div>
          </div>
      }
      {activeTrips?.length > 0 &&
          <>
              <p className={styles.title}>{t('tracking_page.delivery_trips_title')}</p>
              <div className={styles.deliveryTripCards}>
                {activeTrips?.map((data, index) => (
                  <DeliveryTripCard
                    key={index}
                    dateRange={{from: data.dispatch_period_start, to: data.dispatch_period_end}}
                    place={{from: data.departure_point, to: data.destination_point}}
                    status={mapTripStatus(data.state)}
                    requests={{max: data?.requests?.requested, current: data?.requests?.approved}}
                    onClick={() => handleNavigateToCourierInfo(data.id)}
                  />
                )).reverse()}
              </div>
          </>
      }
      {isEmpty && !loading && <EmptyTracking/>}
      <Navigation/>
    </div>
  );
});

export default TrackingPage;
