import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ReviewsList from './ReviewsList/ReviewsList';

import { ReviewInterface, useReviews } from '../../context/ReviewsContext';
import { ReactComponent as EmojiIcon } from '../../assets/icons/emoji.svg';

import styles from './reviews.module.css';
import { useTranslation } from 'react-i18next';
import { getHashedTgIdFromLocalStorage, getProfileFromLocalStorage } from '../../utils/storeActions';

enum TABS_VALUES {
  recipient = 'recipient',
  sender = 'author',
}

interface IProps {
  isCurrentUser?: boolean
  reviews?: ReviewInterface[]
}

const Reviews: FC<IProps> = ({isCurrentUser = true, reviews}) => {
  const [searchParams] = useSearchParams();

  const {t} = useTranslation();

  const {handleGetReviews} = useReviews();
  const [activeTab, setActiveTab] = useState(TABS_VALUES.recipient);
  const [loading, setLoading] = useState(false);
  const [reviewsData, setReviewsData] = useState<ReviewInterface[]>([]);
  const [authorReviewsData, setAuthorReviewsData] = useState<ReviewInterface[]>([]);
  const [recipientReviewsData, setRecipientReviewsData] = useState<ReviewInterface[]>([]);

  const handleFetchReviews = async () => {

    setLoading(true);
    const localStoreTgId = getHashedTgIdFromLocalStorage()

    const recipientReviewsData = await handleGetReviews(localStoreTgId || searchParams.get('hashed-tg-id') as string, TABS_VALUES.recipient);
    const authorReviewsData = await handleGetReviews(localStoreTgId || searchParams.get('hashed-tg-id') as string, 'sender');
    // setReviewsData(data.results);
    setAuthorReviewsData(authorReviewsData?.results)
    setRecipientReviewsData(recipientReviewsData?.results)
    setLoading(false);

  }

  const handleTabChange = async (value: TABS_VALUES) => {
    setLoading(true);

    setActiveTab(value)
    // setReviewsData([]);
    await handleFetchReviews();
    setLoading(false);
  }

  useEffect(() => {
    if (reviews && reviews?.length) {
      setReviewsData(reviews)
    }
  }, [reviews]);

  useEffect(() => {
    if (searchParams.get('hashed-tg-id') && isCurrentUser) {
      handleFetchReviews();
    }
  }, [searchParams]);

  return (
    <div className={styles.reviews}>
      <div className={styles.reviewsTabs}>
        <div onClick={() => handleTabChange(TABS_VALUES.recipient)}
             className={`${styles.tabItem} ${activeTab === TABS_VALUES.recipient ? styles.active : ''}`}>
          <p>{isCurrentUser ? `${t('reviews.received')} ${recipientReviewsData?.length > 0 ? `(${recipientReviewsData?.length})` : ''} ` : `${t('reviews.title')} ${reviews?.length && reviews?.length > 0 ? `(${reviews?.length})` : ''}`}</p>
          {activeTab === TABS_VALUES.recipient && (<span className={styles.activeBottom}></span>)}
        </div>
        {isCurrentUser && <div onClick={() => handleTabChange(TABS_VALUES.sender)}
                               className={`${styles.tabItem} ${activeTab === TABS_VALUES.sender ? styles.active : ''}`}>
            <p>{t('reviews.given')} {`${authorReviewsData?.length > 0 ? `(${authorReviewsData?.length})` : ''}`}</p>
          {activeTab === TABS_VALUES.sender && (<span className={styles.activeBottom}></span>)}
        </div>}
      </div>
      {loading ? (
        <div className={styles.noReviews}>
          <EmojiIcon/>
          <p className={styles.emptyReviews}>{t('reviews.reviews_loading')}</p>
        </div>
      ) : activeTab === TABS_VALUES.sender ? (
        <ReviewsList reviews={authorReviewsData}/>
      ) : (
        <ReviewsList isAuthor reviews={!isCurrentUser ? reviewsData : recipientReviewsData}/>
      )}

    </div>
  );
};

export default Reviews;
