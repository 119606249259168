import { ProfileInterface } from '../context/ProfileContext';
import globalRequestStore from '../store';

export const saveProfileToLocalStorage = (profile: ProfileInterface) => {
  localStorage.setItem('userProfile', JSON.stringify(profile));
};


export const getProfileFromLocalStorage = (): ProfileInterface | null => {
  const profile = localStorage.getItem('userProfile');
  return profile ? JSON.parse(profile) : null;
};
export const saveHashedTgIdToLocalStorage = (hashedTgId: string) => {
  localStorage.setItem('hashed_tg_id', hashedTgId);
};


export const getHashedTgIdFromLocalStorage = (): string | null => {
  return localStorage.getItem('hashed_tg_id');
};
