import React, { FC, useEffect } from 'react';
import styles from "../CheckYourDeliveryPage/CheckYourDeliveryPage.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PossibleEarningsExplanationPage: FC = () => {
    const navigate = useNavigate();

    const { t } = useTranslation();



    return (
        <div className={styles.pageWrapper}>
            <div className={styles.infoBlock}>
                <p className={styles.text}>
                    {t('possible_earnings_page.final_price_info')}
                </p>
                <p className={styles.textBold}>
                    {t('possible_earnings_page.parcel_types')}
                </p>
                <p className={styles.text}>
                    {t('possible_earnings_page2.documents')}
                </p>
                <p className={styles.text}>
                    {t('possible_earnings_page2.size_s')}
                </p>
                <p className={styles.text}>
                    {t('possible_earnings_page2.size_m')}
                </p>
                <p className={styles.text}>
                    {t('possible_earnings_page2.size_l')}
                </p>
            </div>
        </div>
    );
};

export default PossibleEarningsExplanationPage;
