import React, { createContext, useContext, useState, ReactNode, ComponentType, useCallback } from 'react';
import { PhotoItemType } from '../components/UploadPhotoBlock';
import { generateUUID } from '../utils/generateUUID';


interface PhotosContextType {
  info: string;
  photos: PhotoItemType[];
  setPhotos: React.Dispatch<React.SetStateAction<PhotoItemType[]>>
  handleUpdatePhoto: (photos: PhotoItemType[]) => void;
  handleInfoChange: (e: any) => void;
  clearPhotos: () => void;
}

const PhotosContext = createContext<PhotosContextType | undefined>(undefined);

interface PhotosProviderProps {
  children: ReactNode;
}


export const PhotosProvider: React.FC<PhotosProviderProps> = ({children}) => {
  const [info, setInfo] = useState<string>('');
  const [photos, setPhotos] = useState<PhotoItemType[]>(
    [
      {id: generateUUID(), photo: null},
      {id: generateUUID(), photo: null},
    ]
  )

  const handleInfoChange = (e: any) => {
    setInfo(e);
  };

  const handleUpdatePhoto = (photos: PhotoItemType[]) => setPhotos(photos)
  const clearPhotos = () => {
    setInfo('');
    setPhotos([
      {id: generateUUID(), photo: null},
      {id: generateUUID(), photo: null},
    ]);
  };
  return (
    <PhotosContext.Provider value={{
      info,
      photos,
      setPhotos,
      handleInfoChange,
      handleUpdatePhoto,
      clearPhotos
    }}>
      {children}
    </PhotosContext.Provider>
  );
};


export const usePhotos = (): PhotosContextType => {
  const context = useContext(PhotosContext);
  if (!context) {
    throw new Error('usePhotos must be used within a PhotosProvider');
  }
  return context;
};
