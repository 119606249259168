import React, {FC} from "react";
import styles from "./avatar.module.css";

export enum AvatarSizes {
    s = "s",
    l = "l",
}

interface AvatarProps {
    avatarUrl?: string;
    profileName?: string;
    size?: AvatarSizes;
}

const Avatar: FC<AvatarProps> = ({ avatarUrl, profileName = "P", size = AvatarSizes.l }) => {
    return (
        <div style={{ background: avatarUrl ? `url(${avatarUrl})` : "linear-gradient(180deg, #007AFF 0%, #9B31EF 100%)" }} className={`${styles.avatar} ${styles[size]}`}>
            {!avatarUrl && (
                <p className={styles.avatarContent}>{profileName[0]?.toUpperCase()}</p>
            )}
        </div>
    );
};

export default Avatar;
