import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  ComponentType,
  useCallback,
  useRef,
  useEffect
} from 'react';
import globalRequestStore from '../store';
import { observer } from 'mobx-react-lite';


type ModalProps<P> = P & {
  onClose: () => void;
};

type ModalComponent<P = {}> = ComponentType<ModalProps<P>>;

interface ModalContextType {
  openModal: <P>(Component: ModalComponent<P>, props: P) => void;
  closeModal: () => void;
  modalIsOpen: boolean;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

interface ModalProviderProps {
  children: ReactNode;
}


export const ModalProvider: React.FC<ModalProviderProps> = observer(({children}) => {
  const [modal, setModal] = useState<{ Component: ModalComponent<any>, props: any } | null>(null);

  const ref = useRef(null);


  const openModal = useCallback(<P, >(Component: ModalComponent<P>, props: P) => {
    setModal({Component, props});
  }, []);


  const closeModal = useCallback(() => {
    setModal(null);
  }, []);
  useEffect(() => {
    if (globalRequestStore.settingsIsOpen){
      closeModal()
    }
  }, [globalRequestStore.settingsIsOpen]);
  return (
    <ModalContext.Provider value={{openModal, closeModal, modalIsOpen: modal !== null}}>
      <div ref={ref}>
        {children}
      </div>
      {modal && (
        <modal.Component {...modal.props} onClose={closeModal}/>
      )}
    </ModalContext.Provider>
  );
});


export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
