export type Sizes = {
  Documents: number;
  S: number;
  M: number;
  L: number;
};

type Weights = {
  M?: number;
  L?: number;
};

type ResultItem = {
  size: keyof Sizes;
  price: number;
};

type Result = {
  total: number;
  resultArray: ResultItem[];
};
const defaultWeights: Weights = {
  M: 5,
  L: 20
};

export const calculatePrices = (sizes: Sizes, weights: Weights = defaultWeights): Result => {
  const resultArray: ResultItem[] = [];
  let total = 0;

  Object.entries(sizes).forEach(([size, price]) => {
    let finalPrice = price;


    if (size === 'M' && weights?.M) {
      finalPrice *= weights.M;
    } else if (size === 'L' && weights?.L) {
      finalPrice *= weights.L;
    }

    resultArray.push({size: size as keyof Sizes, price: finalPrice});

    total += finalPrice;
  });

  return {total, resultArray};
};



