import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translation from './translations.json';

const resources = {
    en: {
        translation: translation.en,
    },
    uk: {
        translation: translation.uk,
    },
    ru: {
        translation: translation.ru,
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
