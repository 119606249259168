import { axiosInstance } from '../axiosConfig/axiosConfig';
import { getHashedTgIdFromLocalStorage, getProfileFromLocalStorage } from '../../utils/storeActions';
import globalRequestStore from '../../store';

export interface GetTripsSearchResultsDto {
  from: string;
  to: string;
  parcelSize: string;
  startDate: string;
  endDate: string;
}

export interface PostTripDto {
  departure_point: string;
  destination_point: string;
  dispatch_period_start: string;
  dispatch_period_end: string;
  additional_info: string;
  is_active: boolean;
  courier: number;
  sizes_available: string[];
}

export interface MakeNotificationDto {
  departure_point: string;
  destination_point: string;
  dispatch_period_start: string;
  dispatch_period_end: string;
  user: number | string;
  size: string;
}

export async function getTripsSearchResult(data: GetTripsSearchResultsDto, id: string | undefined): Promise<any> {
  const tgId = getHashedTgIdFromLocalStorage();
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.get(
      `/api/trips/trips/?departure_point=${data.from}&destination_point=${data.to}&dispatch_period_end=${data.endDate}&dispatch_period_start=${data.startDate}&sizes_available=${(data.parcelSize)}`,
      {
        headers: {
          'tg-id': id || tgId
        }
      }
    );
    globalRequestStore.finishLoading();
    return response.data;
  } catch (error: any) {
    globalRequestStore.setError(error.message);
  }
}

export async function getTripsNearestResult(data: GetTripsSearchResultsDto, nearest: string, id: string | undefined): Promise<any> {
  const tgId = getHashedTgIdFromLocalStorage();
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.get(
      `/api/trips/trips/?&nearest=${nearest}&dispatch_period_end=${data.endDate}&dispatch_period_start=${data.startDate}&departure_point=${data.from}&destination_point=${data.to}&sizes_available=${(data.parcelSize)}`,
      {
        headers: {
          'tg-id': id || tgId
        }
      }
    );
    globalRequestStore.finishLoading();
    return response.data;
  } catch (error: any) {
    globalRequestStore.setError(error.message);
  }
}

export async function makeNotification(data: MakeNotificationDto): Promise<any> {
  const tgId = getHashedTgIdFromLocalStorage();
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.post(
      `/api/search/search/`,
      data,
      {
        headers: {
          'tg-id': tgId
        }
      }
    );
    globalRequestStore.finishLoading();
    return response.data;
  } catch (error: any) {
    globalRequestStore.setError(error.message);
  }
}

export async function createTrip(tgId: string, data: PostTripDto): Promise<any> {
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.post(
      `/api/trips/trips/`,
      data,
      {
        headers: {
          'tg-id': tgId
        }
      }
    );
    globalRequestStore.finishLoading();
    return response.data;
  } catch (error: any) {
    globalRequestStore.setError(error.message);
  }
}

interface DeliveryRequest {
  recipient: string;   // ID получателя
  author: string;      // ID автора
  rating: string;      // Рейтинг
  text: string;        // Текст отзыва
  delivery_request: string; // ID запроса на доставку
}


export async function createNewReview(data: DeliveryRequest): Promise<any> {
  const tgId = getHashedTgIdFromLocalStorage();
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.post(
      `/api/reviews/create/`,
      data,
      {
        headers: {
          'tg-id': tgId
        }
      }
    );
    globalRequestStore.finishLoading();
    return response.data;
  } catch (error: any) {
    globalRequestStore.setError(error.message);
  }
}

interface INewRequest {
  size: string;
  additional_info: string;
  trip: string;
  photos: any;
}

export async function createNewRequest(data: INewRequest): Promise<any> {
    const tgId = getHashedTgIdFromLocalStorage();


    try {
        globalRequestStore.startLoading();

        const response = await axiosInstance.post(
          `/api/delivery_requests/active-parcels/`,
          data,
          {
              headers: {
                  "tg-id": tgId
              }
          }
        );
        globalRequestStore.finishLoading();
        return response.data;
    } catch (error:any) {
        globalRequestStore.setError(error.message);
    }
}
